<template>
  <div class="body">
    <div ref="myDiv" style="left: 0;right: 0;top: 0;position: fixed;z-index:999;width: 100vw;background: #F7F7F9">
      <!--  日期选择  -->
      <div @click="choiceDate" class="flex_able margin-top40"
           style="padding-left: var(--30);padding-right: var(--30);width: 100%">
        <img style="width: var(--32);height: var(--34);"
             src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/7f8f1566-15c9-4505-8716-4ddafd009ae8.png">
        <div class="font-size30 font-color333333 line-height42 font-weightBold" style="flex: 1;margin-left: var(--16);">
          算力明细（共{{ maxPageSize }}条）
        </div>
        <title-arrow
            v-if="false"
            textColor="#999999"
            lineHeight="var(--34)"
            textSize="var(--25)"
            imageWidth="var(--24)"
            imageHeight="var(--14)"
            marginLeft="var(--8)"
            imageUrl="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/47c803e7-4ab3-46bd-9397-b317fa2fe4ae.png"
            :text="selectDate"/>
      </div>
      <!--  流式九宫格  -->
      <div class="grid margin-right30 margin-bottom10"
           style="width: 100vw;position: sticky">
        <div @click="clickFunctionType(index)" v-for="(item,index) in tabList" :key="item.id"
             class="item flex_center" :class="{ 'item-selected': activeIndex == index }">
          <div class="item-title" :class="{ 'item-title-selected': activeIndex == index }">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div :style="{'padding-top': divHeight + 'px'}">
      <van-list v-model=item.loading :immediate-check="false" :finished=item.finished
                :finished-text=item.finishedText
                @load="getTabListContent(item)"
                v-if="item.contentList.length > 0">
        <div v-for="(item, index) in item.contentList" :key="index">
          <div class="item_bg flex_able flex-directionColumn"
               style="align-items: start;padding-top: var(--40);padding-bottom: var(--40);">
            <div class="flex_able" style="width: var(--690);padding-left: var(--40);padding-right: var(--40);">
              <img style="width: var(--56);height: var(--56);"
                   src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/dd1bdec2-80ff-441f-9c19-dec356cb72d8.png"
                   alt=""/>
              <span class="font-size30 font-weightBold font-color1B1B1B line-height42 margin-left20">
                  {{ item.assetTypeName }} {{ item.awardValue }}P
              </span>
              <div style="flex: 1"></div>
              <div v-if="item.isAward == 2"
                   class="font-size30 font-color026AFC line-height42 font-weightBold margin-left30">
                进行中
              </div>
              <div v-if="item.orderStatus == 1"
                   class="font-size30 font-color999999 line-height42 font-weightBold margin-left30">
                未生效
              </div>
              <div v-if="item.orderStatus == 3"
                   class="font-size30 font-color999999 line-height42 font-weightBold margin-left30">
                已过期
              </div>
            </div>

            <div class="margin-top30 margin-left30 flex_center flex-directionColumn"
                 style="width: var(--630);height: var(--168);background: #FAFAFA;border-radius: var(--30);">
              <div class="font-size30 font-color1B1B1B line-height42">
                <span>获取时间</span>
                <img style="width: var(--158);height: var(--17);margin-left: var(--26);margin-right: var(--26);"
                     src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/8018e208-b40f-418b-9d5d-3ddd2c8902e4.png">
                <span>到期时间</span>
              </div>
              <div class="font-size24 line-height34 margin-top12" style="color:#7E90A0;">
                <span style="margin-right: var(--104);">{{ item.takeEffectTime }}</span>
                <span>{{ item.invalidTime }}</span>
              </div>
            </div>
          </div>
        </div>
      </van-list>

      <div
          style="text-align: center;width: 94.8vw;border-radius: 1.3vw;position: relative;line-height: 19.7vw;color: #000;font-size: 4vw;font-weight: bold;margin: 2.8vw auto auto auto;"
          v-if="item.showNoData">
        <img style="width: var(--350);height: var(--288);margin-top: var(--188);"
             src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/58575b99-c219-4367-aeb9-ec2885ce6ab1.png">
        <div class="margin-top40 font-color999999 font-size28 line-height40">
          暂无明细
        </div>
      </div>
    </div>
    <!-- 时间选择器 -->
    <date-choice-dialog type="year-month" ref="controlDateDialog" @handleChange="dateCallback"
                        @handleChangeSelectDate="selectDateCallback"/>
  </div>
</template>

<script>

import TitleArrow from "@/components/TitleArrow.vue";
import {Api} from "@/utils/net/Api";
import {Toast} from "vant";
import DateChoiceDialog from "@/components/DateChoiceDialog.vue";

export default {
  components: {DateChoiceDialog, TitleArrow},
  data() {
    return {
      item: {contentList: []},
      divHeight: 0,
      activeIndex: 0,
      selectDate: "",//显示选中的时间
      monthNum: "",//给接口的时间
      tabList: [],
      maxPageSize: 0,
    }
  },
  created() {
    //开始5
    try {
      //设置标题
      document.title = "算力明细"
    } catch (error) {
      console.log("常规错误:" + error)
    }
  },
  mounted() {
    this.$nextTick(() => {
      //获取顶部的高度
      const height = this.$refs.myDiv.clientHeight;
      console.log('The height of myDiv is:', height);
      this.divHeight = height
    });
  },
  methods: {
    clickFunctionType(index) {
      console.log(index)
      this.activeIndex = index
      this.tabList = this.getTabList()
      this.item = this.tabList[this.activeIndex]
      this.getTabListContent(this.item)
    },
    getTabList() {
      return [
        {
          title: "全部",
          type: 0,
          finished: false,
          showNoData: false,
          pageNo: 1,
          loading: false,
          finishedText: "没有更多了",
          pageSize: 10,
          contentList: [],
        },
        {
          title: "进行中",
          type: 1,
          finished: false,
          showNoData: false,
          pageNo: 1,
          loading: false,
          finishedText: "没有更多了",
          pageSize: 10,
          contentList: [],
        },
        {
          title: "已到期",
          type: 2,
          finished: false,
          showNoData: false,
          pageNo: 1,
          loading: false,
          finishedText: "没有更多了",
          pageSize: 10,
          contentList: [],
        }
      ]
    },
    //组件回调 经过各种判断后返回加密后文字
    selectDateCallback(value) {
      console.log(value);
      this.selectDate = value
    },
    //时间选择器 处理后给接口的日期格式
    dateCallback(value) {
      console.log(value);
      this.monthNum = value
      this.tabList = this.getTabList()
      this.item = this.tabList[this.activeIndex]
      this.getTabListContent(this.item)
      // this.tabList.forEach((item, index) => {
      //   this.getTabListContent(item, index)
      // })
    },
    choiceDate() {
      return
      //显示日期
      // this.$refs.controlDateDialog.controlDialog(true);
    },
    getTabListContent(item) {
      let tab = item
      if (tab.finished) {
        return;
      }
      let data = {
        pageNo: tab.pageNo,
        pageSize: tab.pageSize,
        type: tab.type,
        // monthNum: this.monthNum
      }
      let apiObj = Api.queryOrderList(data);
      if (apiObj) {
        apiObj.then(res => {
          if (res.code === 100) {
            let pageInfo = res.data.pageInfo
            let resData = pageInfo,
                list = resData.list;
            if (resData.isLastPage) { // 没有更多了
              tab.finished = true;
            }
            this.maxPageSize = pageInfo.total
            tab.contentList = tab.contentList.concat(this.handleList(list));
            item.showNoData = item.contentList.length === 0
            tab.pageNo = resData.nextPage;
            tab.loading = false;
          } else {
            Toast(res.msg);
          }
        })
      }
    },
    handleList(list) {
      // list.forEach(item => {
      //
      // })
      return list;
    },
  },
}
</script>

<style scoped>
.body {
  background-color: #F7F7F9;
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(4, 1fr);
}

.item {
  margin-top: var(--20);
  padding-left: var(--30);
  padding-right: var(--30);
  margin-left: var(--30);
  height: var(--66);
  background: transparent;
  border-radius: var(--12);
}

.item-selected {
  background: #268DFF;
}

.item-title {
  font-size: var(--30);
  line-height: var(--42);
  color: #666666;
}

.item-title-selected {
  color: #ffffff;
  font-weight: bold;
}

.item_bg {
  margin-top: var(--22);
  margin-left: var(--30);
  width: var(--690);
  border-radius: var(--24);
  background: #FFFFFF;
}
</style>
